import styled from 'styled-components'
import { media } from '@media'

export const BusinessListWrapper = styled.div``

export const BusinessTitle = styled.h2``

export const ListItemWrapper = styled.div``

export const BusinessList = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
        flex-direction: row;
    `};
`

export const LogoBoxList = styled.div`
  flex: 1;
  text-align: center;
  order: 1;

  img {
    margin-bottom: 1em;
  }

  ${media.tablet`
        text-align: right;
        order: 2;
    `};
`

export const ExcerptList = styled.div`
  flex: 3;
  order: 2;

  ${media.tablet`
        order: 1;
    `};
`
