import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Label, Select } from './styles'

const JumpCategory = ({ jumpCategoryData }) => {
  const [jumpItem, setJumpItem] = useState('Please select..')

  const handleChange = (e) => {
    navigate('/businesses/' + e + '/')
    setJumpItem(e)
  }

  return (
    <div>
      <span>
        <Label className="sc-Label">
          Business Filter &#x25BC;
          <Select className="sc-Select" value={jumpItem} onChange={(e) => handleChange(e.target.value)}>
            <option value="accommodation">Please select..</option>
            {jumpCategoryData.map(({ node }, index) => {
              return (
                <option key={index} value={node.slug}>
                  {node.category}
                </option>
              )
            })}
          </Select>
          ​
        </Label>
      </span>
    </div>
  )
}

export default JumpCategory
