import React from 'react'
import Layout from '@components/Layout'
import { Seo } from '@components/Seo'
import BusinessCard from '@components/BusinessCard'
import JumpCategory from '@components/JumpCategory'
import { graphql } from 'gatsby'
import PageTitle from '@components/PageTitle'
import { Content } from '@global'

const BusinessCategoryTemplate = ({ data }) => {
  const { allBusinesses, pageCategory, allCategories } = data

  let group = []

  if (allBusinesses !== null) {
    group = allBusinesses.edges
  }

  const seoTitle = pageCategory.alternateDisplayTitle + ' | In Sowerby Bridge The Town and Business Guide For Residents and Visitors'

  const jumpCategoryData = allCategories.edges

  return (
    <Layout>
      <Seo title={seoTitle} />
      <PageTitle title={pageCategory.alternateDisplayTitle} />
      <JumpCategory jumpCategoryData={jumpCategoryData} />
      <Content dangerouslySetInnerHTML={{ __html: pageCategory.contentNode.childMarkdownRemark.html }} />
      <BusinessCard cardData={group} />
    </Layout>
  )
}

export const businessCategoryGraph = graphql`
  query businessCategoryQuery($slug: String!, $businessfilter: String!) {
    allBusinesses: allDatoCmsBusiness(sort: { order: ASC, fields: title }, filter: { tags: { elemMatch: { category: { eq: $businessfilter } } } }) {
      edges {
        node {
          id
          title
          logo {
            gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: TRACED_SVG)
          }
          slug
          shortDescription
        }
      }
    }
    pageCategory: datoCmsBusinessType(slug: { eq: $slug }) {
      slug
      id
      category
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      alternateDisplayTitle
    }
    allCategories: allDatoCmsBusinessType(sort: { fields: category, order: ASC }) {
      edges {
        node {
          id
          category
          slug
        }
      }
    }
  }
`

export default BusinessCategoryTemplate
