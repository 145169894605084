import styled from 'styled-components'
import { colours, size } from '@variables'

export const Label = styled.label`
  @media (min-width: ${size.tablet}) {
  }
`

export const Select = styled.select`
  margin-left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 1px solid ${colours.pgwarmgrey};
  padding: 0.5em;
  width: 100%;
  @media (min-width: ${size.tablet}) {
    margin-left: 0.5em;
    width: 40%;
  }
`
